$(document).ready(function(){


    // Preloader
    $(window).on('load', function() { // makes sure the whole site is loaded 
      $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
      $('.preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
      $('body').delay(350).css({'overflow':'visible'});
    });
    
    // on load
    $(window).on('load', function (){
        // Carousel 
        

        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: true,
            dots: true,
            loop: false,
            margin: 0,
            autoplay:true,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
            
        });

        $('.promocions').owlCarousel({
            pagination: false,
            nav: true,
            dots: false,
            loop: false,
            margin: 0,
            //autoplay:true,
            //autoplayTimeout:5000,
            //autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
            
        });


        $('.last-news').owlCarousel({
            //pagination: false,
            nav: true,
            loop: false,
            dots: false,
            margin: 30,
            autoplay:false,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },
                1000:{
                    items:3
                }
            }
            
        });



        $('.logos').owlCarousel({
          //pagination: false,
          pagination: false,
          nav: true,
          dots: false,
          loop: false,
          margin: 0,
          responsive:{
              0:{
                  items:2
              },
              480:{
                  items:2
              },
              768:{
                  items:3
              },
              992:{
                  items:3
              },
              1200:{
                  items:4
              },
              1800:{
                  items:4
              },
              
          }
          
      });






      $('.galeria-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            margin: 0,
            //autoplay:true,
            //autoplayTimeout:5000,
            //autoplayHoverPause:true
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
            
        });

        



        
        




        $( ".owl-prev").html('<i class="custom-icon-left"></i>');
        $( ".owl-next").html('<i class="custom-icon-right"></i>');





        




    });




// Highlight the top nav as scrolling occurs
$(window).scroll(function() {
  if ($(document).scrollTop() > 400) {
    $('.auto-hide-header').addClass('shrink');
  } else {
    $('.auto-hide-header').removeClass('shrink');
  }
});

// Move element
$(".auto-hide-header").autoHidingNavbar({
    animationDuration:900,
});



/*
  // Mobile menu collapse onclick
  $('.navigation  a').on('click', function(){
      $( ".nav-trigger" ).toggleClass( "collapsed" );
      $( ".navigation" ).toggleClass( "in" );
  });

*/

// Video
$(".video-img").on("click", function(){
      var elm = $(this),
          conts   = elm.contents(),
          le      = conts.length,
          ifr     = null;
      for(var i = 0; i<le; i++){
          if(conts[i].nodeType == 8) ifr = conts[i].textContent;
      }
      elm.addClass("player").html(ifr);
      elm.off("click");
});



// jQuery for page scrolling feature - requires jQuery Easing plugin
$('a.page-scroll').bind('click', function(event) {
    var $anchor = $(this);
    $('html, body').stop().animate({
        scrollTop: ($($anchor.attr('href')).offset().top - 50)
    }, 1250, 'easeInOutExpo');

    event.preventDefault();
});

// Anchor scroll
var $root = $('html, body');
$('a.anchor').click(function() {
    $root.animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top - 110
    }, 1200);
    return false;
});




// Select all links with hashes
$('.navigation a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
});



// Formularis
$(window).load(function(){
    $(".magia input").val("");


    $(".magia input").focusout(function(){
        if($(this).val() !== ""){
            $(this).addClass("has-content");
        }else{
            $(this).removeClass("has-content");
        }
    });

});






// Magic popup
$('.popup-gallery').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        delegate: 'a', // the selector for gallery item
        type: 'image',
        gallery: {
          enabled:true
        }
    });
});


$('.galeria-fotos').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        delegate: 'a', // the selector for gallery item
        type: 'image',
        gallery: {
          enabled:true
        }
    });
});





var groups = {};
$('.galleryItem').each(function() {
      var id = parseInt($(this).attr('data-group'), 10);
      
      if(!groups[id]) {
        groups[id] = [];
      } 
      groups[id].push( this );
});


$.each(groups, function() {
  $(this).magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      gallery: {
        enabled: true, // set to true to enable gallery

        //preload: [0,2], // read about this option in next Lazy-loading section

        navigateByImgClick: true,

        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

        tPrev: 'Previous (Left arrow key)', // title for left button
        tNext: 'Next (Right arrow key)', // title for right button
        tCounter: '<span class="mfp-counter">< %curr%/%total% ></span>' // markup of counter
      }
  });
});



// Add class a todos los href a images
$('.post-text a[href*=".png"], .post-text a[href*=".jpg"]').each(function() {
    if (this.href.indexOf('?') < 0) {
         $(this).addClass('popup-link');
    }
});

$('.popup-link').magnificPopup({
  type: 'image'
  // other options
});


$('.popup-modal').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});




$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,

  fixedContentPos: false
});






    





});
